import { DRAFT_SAVED, DRAFT_STORED } from "./actions/constants";
import {
  EmptyDraft,
  WorkoutModel,
} from "./components/Workout/Models/Workout.model";
import { AnyAction } from "redux";

interface PersonalWorkouts {
  draft: WorkoutModel;
  personalWorkouts: WorkoutModel[];
}

const INITIAL_STATE: PersonalWorkouts = {
  draft: EmptyDraft,
  personalWorkouts: [],
};

const workoutReducer = (state = INITIAL_STATE, action: AnyAction) => {
  const { type, draft, workout } = action;

  switch (type) {
    case DRAFT_SAVED:
      return { ...state, draft };

    case DRAFT_STORED:
      return {
        ...state,
        draft: EmptyDraft,
        personalWorkouts: [...state.personalWorkouts, state.draft],
      };
    default:
      return state;
  }
};
export default workoutReducer;
