import { SIGNUP_OK, SIGNUP_FAIL } from "./constants";
import { verifyEmail } from "./verifyEmailAction";
import authentication from "../config/firebase";

export const signUpOk = () => {
  return {
    type: SIGNUP_OK,
  };
};

export const signUpFail = (error: any) => {
  return {
    type: SIGNUP_FAIL,
    error,
  };
};

export const signUp = (email: string, password: string) => {
  return (dispatch: any) => {
    authentication
      .createUserWithEmailAndPassword(email, password)
      .then((res: any) => {
        return !res.user.emailVerified
          ? dispatch(verifyEmail(res.user))
          : dispatch(signUpOk());
      })
      .catch((err: any) => {
        return dispatch(signUpFail(err));
      });
  };
};
