import { AUTH_CHANGED } from "./constants";
import authentication, { FirebaseUser } from "../config/firebase";
import { AuthUser } from "./AuthUser.model";
import { AppDispatch } from "../store";
import { firebaseUserToAuthUser } from "./AuthUtils";

export const authChanged = (user: AuthUser) => {
  return {
    type: AUTH_CHANGED,
    user,
  };
};

export const onAuthStateChanged = () => {
  return (dispatch: AppDispatch) => {
    return authentication.onAuthStateChanged((firebaseUser: FirebaseUser) => {
      const user = firebaseUserToAuthUser(firebaseUser);
      // console.log("dispatcing authChanged with", user);
      return dispatch(authChanged(user));
    });
  };
};
