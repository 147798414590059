import { FirebaseUser } from "../config/firebase";
import { AuthUser } from "./AuthUser.model";

export const firebaseUserToAuthUser = (
  firebaseUser: FirebaseUser
): AuthUser => {
  const user: AuthUser = {
    UserHandle: firebaseUser?.displayName || firebaseUser?.email || "",
    IsAuthenticated:
      firebaseUser?.displayName || firebaseUser?.email ? true : false,
    IsEmailVerified: firebaseUser?.emailVerified,
  };
  return user;
};
