import { VERIFICATIONEMAIL_SENT, VERIFICATIONEMAIL_ERROR } from "./constants";

export const verifyEmail = (user: any) => {
  return (dispatch: any) => {
    user
      .sendEmailVerification()
      .then(() => dispatch(verificationEmailSent()))
      .catch((err: any) => dispatch(verificationEmailError(err)));
  };
};

export const verificationEmailSent = () => {
  return {
    type: VERIFICATIONEMAIL_SENT,
  };
};

export const verificationEmailError = (error: any) => {
  return {
    type: VERIFICATIONEMAIL_ERROR,
    error,
  };
};
