import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { useDispatch, TypedUseSelectorHook, useSelector } from "react-redux";
import authReducer from "./authReducer";
import workoutReducer from "./workoutReducer";

const rootReducer = combineReducers({
  authReducer: authReducer,
  workoutReducer: workoutReducer,
});

// init store documentation with Typescript: https://redux-toolkit.js.org/usage/usage-with-typescript

const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export default store;
