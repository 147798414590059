import { AnyAction } from "redux";
import { AuthUser, NullAuthUser } from "./actions/AuthUser.model";
import {
  AUTH_CHANGED,
  SIGNIN_FAIL,
  SIGNIN_OK,
  SIGNOUT_FAIL,
  SIGNOUT_OK,
  SIGNUP_FAIL,
  SIGNUP_OK,
} from "./actions/constants";

const INITIAL_STATE: { user: AuthUser; error: any } = {
  user: NullAuthUser,
  error: null,
};

const authReducer = (state = INITIAL_STATE, action: AnyAction) => {
  const { type, user, error } = action;
  // console.log("authChangeReducer type", type);
  // console.log("authChangeReducer user", user);

  switch (type) {
    case AUTH_CHANGED:
    case SIGNIN_OK:
    case SIGNUP_OK:
      return { ...state, user, error: null };
    case SIGNIN_FAIL:
    case SIGNUP_FAIL:
    case SIGNOUT_FAIL:
      return { ...state, user: NullAuthUser, error };
    case SIGNOUT_OK:
      return { ...state, user: NullAuthUser, error: null };

    default:
      return state;
  }
};
export default authReducer;
