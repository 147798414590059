export interface AuthUser {
  UserHandle: string;
  IsAuthenticated: boolean;
  IsEmailVerified: boolean;
}

export const NullAuthUser = {
  UserHandle: "",
  IsAuthenticated: false,
  IsEmailVerified: false,
};
