const firebaseConfig = {
  apiKey: "AIzaSyB4BS7nInmDlzOa9sSCDun79wBu8DgSlUI",
  authDomain: "workouts-e0c49.firebaseapp.com",
  projectId: "workouts-e0c49",
  storageBucket: "workouts-e0c49.appspot.com",
  messagingSenderId: "28447408385",
  appId: "1:28447408385:web:1752a08ae2fb11b64a1b00",
  measurementId: "G-QJNCQTL9MY",
};

export default firebaseConfig;
