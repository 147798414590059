import { SIGNIN_OK, SIGNIN_FAIL, SIGNOUT_OK, SIGNOUT_FAIL } from "./constants";
import authentication from "../config/firebase";
import { AppDispatch } from "../store";
import { AuthUser } from "./AuthUser.model";
import { browserLocalPersistence, UserCredential } from "@firebase/auth";
import { firebaseUserToAuthUser } from "./AuthUtils";

export const signInOk = (user: AuthUser) => {
  return {
    type: SIGNIN_OK,
    user,
  };
};

export const signInFail = (error: any) => {
  return {
    type: SIGNIN_FAIL,
    error,
  };
};

export const signedOutOk = () => {
  return {
    type: SIGNOUT_OK,
  };
};

export const signedOutFail = (error: any) => {
  return {
    type: SIGNOUT_FAIL,
    error,
  };
};

export const signIn = (email: string, password: string) => {
  return (dispatch: AppDispatch) => {
    authentication.setPersistence(browserLocalPersistence).then(() =>
      authentication
        .signInWithEmailAndPassword(email, password)
        .then((res: UserCredential) => {
          const authUser = firebaseUserToAuthUser(res.user);
          return dispatch(signInOk(authUser));
        })
        .catch((err: any) => dispatch(signInFail(err)))
    );
  };
};

export const signOut = () => {
  return (dispatch: AppDispatch) => {
    authentication
      .signOut()
      .then(() => dispatch(signedOutOk()))
      .catch((err: any) => dispatch(signedOutFail(err)));
  };
};
