export const SIGNIN_OK = "SIGNIN_OK";
export const SIGNIN_FAIL = "SIGNIN_FAIL";

export const SIGNOUT_OK = "SIGNOUT_OK";
export const SIGNOUT_FAIL = "SIGNOUT_FAIL";

export const SIGNUP_OK = "SIGNUP_OK";
export const SIGNUP_FAIL = "SIGNUP_FAIL";

export const AUTH_CHANGED = "AUTH_CHANGED";

export const VERIFICATIONEMAIL_SENT = "VERIFICATIONEMAIL_SENT";
export const VERIFICATIONEMAIL_ERROR = "VERIFICATIONEMAIL_ERROR";

export const DRAFT_SAVED = "DRAFT_SAVED";
export const DRAFT_STORED = "DRAFT_STORED";
