import { Exercise } from "./Exercise.model";

export interface WorkoutModel {
  title?: string;
  exercises: Exercise[];
  sets?: number;
  repsPerSet?: number;
}

export const EmptyDraft: WorkoutModel = {
  exercises: [],
  title: "",
  sets: 0,
  repsPerSet: 0,
};
