import config from "./config";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  User,
  setPersistence,
  Persistence,
} from "firebase/auth";
export type { User as FirebaseUser };

const app = initializeApp(config);
const auth = getAuth(app);

const authentication = {
  setPersistence: (persistence: Persistence) =>
    setPersistence(auth, persistence),
  signInWithEmailAndPassword: (username: string, password: string) =>
    signInWithEmailAndPassword(auth, username, password),
  signOut: () => signOut(auth),
  createUserWithEmailAndPassword: (username: string, password: string) =>
    createUserWithEmailAndPassword(auth, username, password),
  onAuthStateChanged: (nextFn: any, errFn?: any, completeFn?: any) =>
    onAuthStateChanged(auth, nextFn, errFn, completeFn),
};
export default authentication;

// solve DB
// import { getFirestore, collection, getDocs } from "firebase/firestore/lite";
// import { User as FirebaseUser } from "@firebase/auth";

// export const firestoreDb = getFirestore(firebaseApp);
