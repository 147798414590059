import React, { Suspense, useEffect } from "react";
import "./App.css";
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles";
import { blue, grey } from "@mui/material/colors";
import { RootState, useAppDispatch, useAppSelector } from "../../store";
import { onAuthStateChanged } from "../../actions";

// docs: https://mui.com/material-ui/customization/theming/#themeprovider

export let mainTheme = responsiveFontSizes(
  createTheme({
    spacing: 8,
    palette: {
      primary: blue,
      secondary: grey,
    },
  })
);

const App = () => {
  const dispatch = useAppDispatch();

  const isAuthenticated = useAppSelector(
    (state: RootState) => state.authReducer.user.IsAuthenticated
  );

  useEffect(() => {
    window.HELP_IMPROVE_VIDEOJS = false;
    const unsubscribe = dispatch(onAuthStateChanged());
    return () => unsubscribe();
  });

  const LazyAppRoutesLoading = React.lazy(() => import("./AppRoutes"));

  return (
    <ThemeProvider theme={mainTheme}>
      <Suspense fallback={<div>...loading</div>}>
        <LazyAppRoutesLoading isAuthenticated={isAuthenticated} />
      </Suspense>
    </ThemeProvider>
  );
};

export default App;
